<template>
  <div id="app" class="align-middle marg-top-custom">
    <form style="width:95%">
          <fieldset>
              <div class="form-group col-md-12 marg-top-custom">
                <div class="input-group">
                  <span class="input-group-prepend" style="width: 34px;">
                    <span class="input-group-text">
                      <i class="fas fa-user"></i>
                    </span>
                  </span>
                  <input
                    maxlength="80"
                    required
                    id="name"
                    name="name"
                    type="text"
                    class="form-control"
                    placeholder="Cuál es tu nombre y apellido?"
                    v-model.trim="user.name"
                    v-bind:class="{ 'is-invalid': errors.includes('name') }"
                  />
                  <div class="invalid-feedback">Por favor ingrese su nombre y apellido</div>
                </div>
              </div>

             
              <div class="form-group col-md-12">
                <div class="input-group">
                  <span class="input-group-prepend" style="width: 34px;">
                    <span class="input-group-text">
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                  </span>
                  <input
                    type="text"
                    maxlength="80"
                    required
                    id="direction"
                    name="direction"
                    class="form-control"
                    placeholder="Ingrese su dirección"
                    v-model.trim="user.direction"
                    v-bind:class="{ 'is-invalid': errors.includes('direction') }"
                  />
                  <div class="invalid-feedback">Por favor ingrese su dirección</div>
                </div>
              </div>


              <div class="form-group col-md-12">
                <div class="input-group">
                  <span class="input-group-prepend" style="width: 34px; ">
                    <span class="input-group-text">
                      <i class="fas fa-mobile-alt"></i>
                    </span>
                  </span>
                  <input
                    type="number"
                    maxlength="80"
                    required
                    id="cel"
                    name="cel"
                    v-mask="'#########'"
                    class="form-control"
                    placeholder="Ingrese su celular"
                    v-model.trim="user.cel"
                    v-bind:class="{ 'is-invalid': errors.includes('cel') }"
                  />
                  <div class="invalid-feedback">Debe ingresar su celular</div>
                </div>
              </div>

              <div class="form-group col-md-12">
                <div class="input-group">
                  <span class="input-group-prepend" style="width: 34px;">
                    <span class="input-group-text">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </span>
                  <input
                    type="email"
                    maxlength="80"
                    required
                    id="email"
                    name="email"
                    class="form-control"
                    placeholder="Ingrese su email"
                    v-model.trim="user.email"
                    v-bind:class="{ 'is-invalid': errors.includes('email') }"
                  />
                  <div class="invalid-feedback">Por favor ingrese su Email</div>
                </div>
              </div>

              <div class="form-group col-md-12">
                <div class="input-group">
                  <span class="input-group-prepend" style="width: 34px; ">
                    <span class="input-group-text">
                      <i class="fas fa-clipboard"></i>
                    </span>
                  </span>
                  <input
                    id="order_notes"
                    name="order_notes"
                    maxlength="80"
                    class="form-control"
                    placeholder="Deseas agregar alguna nota al pedido?"
                    v-model.trim="user.note"
                  />
                </div>
              </div>


              <div class="col-md-12">
                <small class="form-text text-muted backend-error" v-if="serverResponse">
                  {{ serverResponse }}
                </small>
              </div>
            
            
              <div class="col-md-12">
                <button
                  type="button"
                  class="btn btn-block btn-primary select"
                  v-on:click="checkForm"
                >Enviar datos</button>
              </div>            
          </fieldset>
        </form>
  </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5'

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';


export default {
  data() {
    return {
      momentsSDK: null,
      hasErrors: false,
      user: {
        name: null,
        direction: null,
        cel: null,
        note: null,
        email: null
      },
      errors: [],
      serverResponse: null,
    };
  },
  methods: {
    checkForm() {
      this.errors = [];
      this.serverResponse = null;

      if (!this.user.name) {
        this.errors.push("name")
      }
      if (!this.user.direction) {
        this.errors.push("direction")
      }
      if (!this.user.cel) {
        this.errors.push("cel")
      }
      if (!this.user.email) {
        this.errors.push("email")
      }
      if (this.user.note == null) {
        this.user.note = "No"
      }
      if (this.errors.length === 0) {
        this.signup();
      }
    },
  async signup(){
    if (!this.momentsSDK) {
      return;
    }
    const formData = {
      name: this.user.name,
      email: this.user.email,
      direction: this.user.direction,
      cel: this.user.cel,
      note: this.user.note,
    };
    console.log("form data: ");
    console.log(formData);

    const attrs = {
      default_name: this.user.name,
      default_email: this.user.email,
      direccion: this.user.direction,
      celular: this.user.cel,
      order_notes: this.user.note,
    };
    console.log("attrs: ");
    console.log(JSON.stringify(attrs));
    this.momentsSDK.setAttributes(attrs);
    this.momentsSDK.sendMessage({text: `${this.user.name} los datos fueron tomados exitosamente`});
    this.momentsSDK.close()
  },
  },
  async created() {
    createMomentsSDK({
      title: 'Proporciona los siguientes datos',
    }).then((momentsSDK) => {
      this.momentsSDK = momentsSDK;
    });
  },
};
</script>

<style lang="sass">

    #app
      padding: 0px

</style>