<template>
  <div id="app" class="align-middle">
    <form>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="input-group">
            <span class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-user"></i>
              </span>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="usuario"
              v-model.trim="username"
              v-bind:class="{ 'is-invalid': errors.includes('username') }"
            />
            <div class="invalid-feedback">Debe ingresar su nombre de usuario</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-12">
          <div class="input-group">
            <span class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-lock"></i>
              </span>
            </span>
            <input
              type="password"
              class="form-control"
              placeholder="contraseña"
              autocomplete="off"
              v-model.trim="password"
              v-bind:class="{ 'is-invalid': errors.includes('password') }"
            />
            <div class="invalid-feedback">Debe ingresar su contraseña</div>
          </div>
          <small class="form-text text-muted login-error" v-if="authError">
            Usuario y/o contraseña incorrectos
          </small>
          <small class="form-text text-muted login-error" v-if="serverError">
            Error al conectarse con el servidor
          </small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-block btn-primary select"
            v-bind:disabled="loading"
            v-on:click="checkForm"
          >Login</button>
        </div>
      </div>
      <!-- <div class="row password-reset">
        <div class="col-md-12">
          <a v-on:click="resetPassword">Olvidé mi contraseña</a>
        </div>
      </div> -->
    </form>
  </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5';

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization';

export default {
  data() {
    return {
      theme: '#333333',
      momentsSDK: null,
      username: null,
      password: null,
      errors: [],
      authError: false,
      serverError: false,
      loading: false,
    };
  },
  methods: {
    resetPassword(e) {
      e.preventDefault();
      this.momentsSDK.sendMessage({ text: 'Reset Password' });
      this.momentsSDK.close();
    },

    checkForm() {
      this.loading = true;
      this.errors = [];

      if (!this.username) {
        this.errors.push('username');
      }
      if (!this.password) {
        this.errors.push('password');
      }

      if (this.errors.length === 0) {
        this.serverError = false;
        this.authError = false;
        this.login();
      } else {
        this.loading = false;
      }
    },

    async login() {
      if (!this.momentsSDK) {
        return;
      }

      axios
        .post(`${this.$root.$options.apiRootUrl}/users/authorization`, {
          username: this.username,
          password: this.password,
          token: this.$root.$options.apiToken,
        })
        .then((response) => {
          if (response.data.error) {
            this.loading = false;
            this.authError = true;
          } else {
            const data = response.data.response;

            const attrs = {
              default_name: data.name,
              user_email: data.email,
              default_id: data.id,
              default_real_name: `${data.name} ${data.surnames}`,
              default_email: encodeURIComponent(data.email),
              phone: data.phone,
              banned: data.active ? 'false' : 'true',
              membership: data.membership,
            };

            this.momentsSDK.setAttributes(attrs);
            this.momentsSDK.sendMessage({ text: `${data.name} bienvenido a Life Aguada 🏋` });
            this.momentsSDK.close();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.serverError = error;
        });
    },
  },
  async created() {
    createMomentsSDK({
      title: 'Life Aguada - Login',
    }).then((momentsSDK) => {
      this.momentsSDK = momentsSDK;
    });
  },
};
</script>

<style lang="sass">
    *
        margin: 0
        padding: 0
        box-sizing: border-box
        outline: none

    html, body, #app
        height: 100%
        width: 100%

    #app
        padding: 10px
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

    a
      color: #037BFF !important
      cursor: pointer

    .password-reset
      margin-top: 15px

    .login-error
      margin-top: 10px
      text-align:center !important
      color: #c4183c !important

    button.select
        margin-top: 15px
        padding: 10px
        color: #ffffff
        border: none
        border-radius: 4px
        font-size: 16px
        -webkit-backface-visibility: hidden
        transition: all .3s
        cursor: pointer
        &:hover
            opacity: 0.8
        &:active
            opacity: 0.5
        &:disabled
            opacity: 0.2
</style>
