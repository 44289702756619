<template>
  <div id="app" class="align-middle">
    <form>
      <fieldset>
        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="nombre"
                v-model.trim="user.name"
                v-bind:class="{ 'is-invalid': errors.includes('name') }"
              />
              <div class="invalid-feedback">Debe ingresar su nombre</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="apellido"
                v-model.trim="user.lastname"
                v-bind:class="{ 'is-invalid': errors.includes('lastname') }"
              />
              <div class="invalid-feedback">Debe ingresar su apellido</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-at"></i>
                </span>
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="email"
                v-model.trim="user.email"
                v-bind:class="{ 'is-invalid': errors.includes('email') }"
              />
              <div class="invalid-feedback">Debe ingresar su email</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-at"></i>
                </span>
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="confirmar email"
                v-model.trim="user.email_confirmation"
                v-bind:class="{ 'is-invalid': errors.includes('email_confirmation') }"
              />
              <div class="invalid-feedback">Debe coincidir con el email ingresado</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-id-card-o"></i>
                </span>
              </span>
              <input
                inputmode="numeric"
                type="number"
                class="form-control"
                placeholder="documento (sin puntos ni guiones)"
                v-model.trim="user.document"
                v-bind:class="{ 'is-invalid': errors.includes('document') }"
              />
              <div class="invalid-feedback">Debe ingresar su documento</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-id-card-o"></i>
                </span>
              </span>
              <input
                inputmode="numeric"
                type="number"
                class="form-control"
                placeholder="confirmar documento (sin puntos ni guiones)"
                v-model.trim="user.document_confirmation"
                v-bind:class="{ 'is-invalid': errors.includes('document_confirmation') }"
              />
              <div class="invalid-feedback">Debe coincidir con el documento ingresado</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-mobile"></i>
                </span>
              </span>
              <input
                inputmode="numeric"
                type="number"
                v-mask="'#########'"
                class="form-control"
                placeholder="celular"
                v-model.trim="user.celphone"
                v-bind:class="{ 'is-invalid': errors.includes('celphone') }"
              />
              <div class="invalid-feedback">Debe ingresar su celular</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-calendar"></i>
                </span>
              </span>
              <input
                inputmode="numeric"
                type="text"
                class="form-control"
                v-mask="'##/##/####'"
                placeholder="fecha de nacimiento (dd/mm/yyyy)"
                v-model.trim="user.dob"
                v-bind:class="{ 'is-invalid': errors.includes('dob') }"
              />
              <div class="invalid-feedback">Debe ingresar su fecha de nacimiento</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <div class="input-group select-input-group">
              <span class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-venus-mars"></i>
                </span>
              </span>
              <select
                class="form-control select-picker custom-select w-100"
                v-model="user.sex"
                v-bind:class="{ 'is-invalid': errors.includes('sex') }"
              >
                <option value="" selected>Sexo</option>
                <option value="F">Femenino</option>
                <option value="M">Masculino</option>
              </select>
              <div class="invalid-feedback">Debe ingresar su sexo</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <small class="form-text text-muted backend-error" v-if="serverResponse">
              {{ serverResponse }}
            </small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              type="button"
              class="btn btn-block btn-primary select"
              v-on:click="checkForm"
              v-bind:disabled="loading"
            >Registrarte</button>
          </div>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5';

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization';

export default {
  data() {
    return {
      momentsSDK: null,
      hasErrors: false,
      user: {
        name: null,
        lastname: null,
        email: null,
        document: null,
        celphone: null,
        dob: null,
        sex: null,
        address: null,
      },
      errors: [],
      serverResponse: null,
      loading: false,
    };
  },
  methods: {
    checkForm() {
      this.loading = true;
      this.errors = [];
      this.serverResponse = null;

      if (!this.user.name) {
        this.errors.push('name');
      }
      if (!this.user.lastname) {
        this.errors.push('lastname');
      }
      if (!this.user.email) {
        this.errors.push('email');
      }
      if (this.user.email !== this.user.email_confirmation) {
        this.errors.push('email_confirmation');
      }
      if (!this.user.document) {
        this.errors.push('document');
      }
      if (this.user.document !== this.user.document_confirmation) {
        this.errors.push('document_confirmation');
      }
      if (!this.user.celphone) {
        this.errors.push('celphone');
      }
      if (!this.user.dob) {
        this.errors.push('dob');
      }
      if (!this.user.sex) {
        this.errors.push('sex');
      }
      if (this.errors.length === 0) {
        this.signup();
      } else {
        this.loading = false;
      }
    },

    async signup() {
      if (!this.momentsSDK) {
        return;
      }

      const dateReg = /^(\d{2})\/(\d{2})\/(\d{4})$/;
      const birthdate = this.user.dob.match(dateReg);

      const signupData = {
        token: this.$root.$options.apiToken,
        first_name: this.user.name,
        last_name: this.user.lastname,
        email: this.user.email,
        identification: this.user.document,
        phone: this.user.celphone,
        sex: this.user.sex,
        birthdate: `${birthdate[1]}/${birthdate[2]}/${birthdate[3]}`,
        address1: 'ND',
      };

      axios
        .post(`${this.$root.$options.apiRootUrl}/customers/registration`, signupData)
        .then((response) => {
          this.loading = false;

          if (response.data.error) {
            console.log('Error: ');
            console.log(response.data.error);
            this.serverResponse = response.data.error;
            this.signupError = true;
          } else {
            const data = response.data.response;

            const attrs = {
              default_name: data.name,
              user_email: encodeURIComponent(data.email),
              default_id: data.id,
              default_real_name: `${data.name} ${data.surnames}`,
              default_email: data.email,
              phone: data.phone,
              banned: data.active ? 'false' : 'true',
              membership: data.membership,
            };

            console.log('Attrs: ');
            console.log(JSON.stringify(attrs));
            this.momentsSDK.setAttributes(attrs);
            this.momentsSDK.sendMessage({ text: `${data.name} bienvenido a Life Aguada 🏋` });
            this.momentsSDK.close();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.serverResponse = error;
          this.signupError = true;
        });
    },
  },
  async created() {
    createMomentsSDK({
      title: 'Life Aguada - Registro',
    }).then((momentsSDK) => {
      this.momentsSDK = momentsSDK;
    });
  },
};
</script>

<style lang="sass">
    *
      margin: 0
      padding: 0
      box-sizing: border-box
      outline: none

    html, body, #app
      height: 100%
      width: 100%

    #app
      padding: 10px
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

    form
      overflow-y: scroll
      overflow-x: hidden

    input, input:focus, input:hover
      font-size: .95rem !important

    .invalid-feedback
        text-align: left;

    .fix-label-item
      height: calc(2.35rem);

    .select-input-group
      flex-wrap: nowrap !important;

    .input-group-text
      width: 2.5rem
      justify-content: center;

    button.select
        width: 100%
        color: #ffffff
        border: none
        border-radius: 4px
        font-size: 16px
        -webkit-backface-visibility: hidden
        transition: all .3s
        cursor: pointer
        &:hover
            opacity: 0.8
        &:active
            opacity: 0.5
        &:disabled
            opacity: 0.2
</style>
