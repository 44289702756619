import Vue from 'vue';
import VCalendar from 'v-calendar';
import VueTheMask from 'vue-the-mask';

import App from './App.vue';
import router from './router';

Vue.use(VCalendar);
Vue.use(VueTheMask);

Vue.config.productionTip = false;

const SINERGIA_TOKEN = 'v8uisbxJ72FY';
const API_ROOT_URL = 'https://verona.osombot.com/';
const FUNCTIONS_ROOT_URL = 'https://us-central1-obot-55a3f.cloudfunctions.net/mercadoPagoVerona';
// const FUNCTIONS_ROOT_URL = 'https://osom-chatbot.ngrok.io';

new Vue({
  router,
  render: (h) => h(App),

  apiToken: SINERGIA_TOKEN,
  apiRootUrl: API_ROOT_URL,

  functionsRootUrl: FUNCTIONS_ROOT_URL,
}).$mount('#app');
