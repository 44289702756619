<template>
  <div id="app" class="align-middle">
    <div id="mostrar">
    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
    <img
      src='https://osomcommerce.com/imgs/Verona/logo%20verona%20300X300-02.png'
      class="imagen"
    />
    <img
      src='https://osomcommerce.com/imgs/Verona/pagaSeguro.jpeg'
      class="imagenMePa" style="width: 70%; margin-bottom: 22px;"
    />

    <form
      id='mercadoPagoForm'
      method='POST'
      :action="`process?token=v8uisbxJ72FY&sessionId=${sessionId}`"
    >
    </form>
  </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5';

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';
axios.defaults.headers.post['Access-Control-Allow-Headers'] =
 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization,X-Frame-Options';

export default {
  data() {
    return {
      sessionId: null,
      direccion: null,
      userName: null,
      orderNotes: null,
      userPhone: null,
      pedidoCliente: null,
      userEmail: null,
      envio: null,
    };
  },
  methods: {
    resetPassword(e) {
      e.preventDefault();
      this.momentsSDK.sendMessage({ text: 'Reset Password' });
      this.momentsSDK.close();
    },
  },
  async created() {
    createMomentsSDK({
      title: 'Verona - MercadoPago',
    }).then((momentsSDK) => {
      this.momentsSDK = momentsSDK;
    });
    
    const payData = {
      amount: this.$route.query.amount,
      email: this.$route.query.email,
      description: this.$route.query.description,
      direccion: this.$route.query.address,
      userName: this.$route.query.name,
      orderNotes: this.$route.query.ordernotes,
      userPhone: this.$route.query.phone,
      pedidoCliente: this.$route.query.pedido,
    };
    console.log(payData);
    axios
      .post(`${this.$root.$options.functionsRootUrl}/pay`, payData)
      .then((response) => {
        $("#mostrar").addClass("disp-none")
        console.log(response);
        const { data } = response;
        this.sessionId = data.sessionId;
        const mpScript = document.createElement('script');
        mpScript.setAttribute('src', 'https://www.mercadopago.com.uy/integrations/v1/web-tokenize-checkout.js');
        mpScript.setAttribute('data-public-key', data.publicKey);
        mpScript.setAttribute('data-transaction-amount', data.amount);
        mpScript.setAttribute('data-customer-id', data.customerId);
        mpScript.setAttribute('data-card-ids', data.cardIds);
        document.getElementById('mercadoPagoForm').appendChild(mpScript);
      });
  },
};
</script>

<style lang="scss">
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
    }
    html, body, #app {
      height: 100%;
      width: 100%;
    }
    #app{
      padding: 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;
      min-height: 100vh;
      text-align: center;
    }
    #app.img{
      padding-top: 30px;
      width: 40%;
      height: auto;
      margin-bottom: 40px;
    }
    #app.span{
      margin-bottom: 50px;
      font-size: 0.875em;
    }
    .disp-none{
      display: none;
    }
    .lds-ring{
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #3498db;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3498db transparent transparent transparent;
    }
  .lds-ring div:nth-child(1){
  animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3){
  animation-delay: -0.15s;
  }
  @keyframes lds-ring{
    0% {
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
</style>
