import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Pay from '../views/Pay.vue';
import Confirmation from '../views/Confirmation.vue';
import SignIn from '../views/SignIn.vue';
import SignUp from '../views/SignUp.vue';
import formDatos from '../views/formDatos.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/payment',
    name: 'Pay',
    component: Pay,
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: Confirmation,
    props: true,
  },
  {
    path: '/form',
    name: 'formDatos',
    component: formDatos,
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
