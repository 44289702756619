<template>
  <div class="confirmation">
    Su pago ha sido procesado
    <button class="continue"
      @click="close()"
      v-bind:disabled="loading"
    >Continuar</button>
  </div>
</template>

<script>
import createMomentsSDK from '@livechat/moments-sdk/es5';
import * as firebase from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  databaseURL: 'https://obot-55a3f-6f99blead.firebaseio.com/',
  // databaseURL: 'http://localhost:9000/?ns=obot-55a3f-6f99blead',
};

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  data() {
    return {
      sessionId: null,
      paymentData: null,
      momentsSDK: null,
      responseText: null,
      loading: true,
      transactionId: null,
    };
  },
  async created() {
    await createMomentsSDK({
      title: '',
    }).then((momentsSDK) => {
      this.momentsSDK = momentsSDK;
    });

    // retrieve information from the db
    this.sessionId = this.$route.query.sessionId;

    await firebase.initializeApp(firebaseConfig);
    const db = firebase.database();
    let paymentDetails;
    let customerEmail;
    await db.ref(this.sessionId).once('value').then((snapshot) => {
      paymentDetails = snapshot.val().payment_details;
      customerEmail = snapshot.val().email;
    });
    this.transactionId = paymentDetails.id;
    let pedidoCasiJson = paymentDetails.metadata.pedido_cliente
    let pedidoCasiJson2 = JSON.parse(pedidoCasiJson)
    const data = {
        personalData:{
          "name": paymentDetails.metadata.user_name,
          "address": paymentDetails.metadata.direccion,
          "email": paymentDetails.metadata.customer_email,
          "phone": paymentDetails.metadata.user_phone,
          "notes": `${paymentDetails.metadata.order_notes}`,
        },
        'order': pedidoCasiJson2,
        "delivery":"ND",
        "subtotal": paymentDetails.transaction_amount,
        "payment": paymentDetails.payment_method_id,
        "transaction_id": paymentDetails.id,
        "orderDate": paymentDetails.date_last_updated,      
    }
    console.info(JSON.stringify(data));
    console.info("---Data")
    this.responseText = 'Hubo un error al procesar su pago';
    await axios.post('https://verona.osombot.com/request', [data]).then((response)=>{ return console.log(response);})
    .catch((error)=>{
      console.log(error);
      this.responseText = "Hubo un error al procesar tu pedido"
      this.momentsSDK.sendMessage({ text: this.responseText });
      this.momentsSDK.close();
      return console.log("error en procesar el pedido");
    });
    const paymentData = {
      token: 'v8uisbxJ72FY',
      first_name: paymentDetails.payer.first_name,
      last_name: paymentDetails.payer.last_name,
      email: customerEmail,
      id: paymentDetails.id,
      first_six_digits: paymentDetails.card.first_six_digits,
      last_four_digits: paymentDetails.card.last_four_digits,
      expiration_month: paymentDetails.card.expiration_month,
      expiration_year: paymentDetails.card.expiration_year,
      date_created: paymentDetails.date_created,
      date_approved: paymentDetails.date_approved,
      operation_type: paymentDetails.operation_type,
      issuer_id: paymentDetails.issuer_id,
      payment_method_id: paymentDetails.payment_method_id,
      payment_type_id: paymentDetails.payment_type_id,
      status: paymentDetails.status,
      status_detail: paymentDetails.status_detail,
      currency_id: paymentDetails.currency_id,
      description: paymentDetails.description,
      installment_amount: paymentDetails.transaction_details.installment_amount,
      total_paid_amount: paymentDetails.transaction_details.total_paid_amount,
      net_received_amount: paymentDetails.transaction_details.net_received_amount,
    };

    //console.info(paymentData);
   // this.responseText = 'Hubo un error al procesar su pago';

    await axios
      .post('https://verona.osombot.com/orders/payment', paymentData)
      .then((response) => {
        if (response.data.error) {
          $("#mostrar").addClass("disp-none")
          console.error('error registro pago');
          console.log(response.data.error);
        } else {
          $("#mostrar").addClass("disp-none")
          console.info('registro pago ok');
          this.responseText = 'Su pago ha sido procesado correctamente';
        }
        this.loading = false;
      })
      .catch((error) => {
        $("#mostrar").addClass("disp-none")
        this.loading = false;
        console.error('Error: error en el registro del pago:');
        console.log(error);
      });
  },
  methods: {
    close() {
      this.momentsSDK.setAttributes({ transaction_id: this.transactionId });
      this.momentsSDK.sendMessage({ text: this.responseText });
      this.momentsSDK.close();
    },
  },
  computed: {
  },
};
</script>

<style lang="scss">
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      outline: none;
    }
    html, body, #app{
      height: 100%;
      width: 100%
    }
    #app{
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .confirmation{
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100vh;
      text-align: center;
    }
    button.continue{
      margin-top: 2em;
      padding: 0 .7em;
      font-size: 1.1em;
      line-height: 2.7;
      background: #009ee3;
      border-radius: 0.2857142857142857em;
      color: #fff;
      cursor: pointer;
      border: 0;
    }
    .disp-none{
      display: none;
    }
    .lds-ring{
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 5px solid #3498db;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3498db transparent transparent transparent;
    }
  .lds-ring div:nth-child(1){
  animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3){
  animation-delay: -0.15s;
  }
  @keyframes lds-ring{
    0% {
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
</style>
